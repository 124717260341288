/* sitewide */
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::selection {
  background-color: var(--color--blood-orange);
  color: var(--color--white);
}

body:has(.age-gate.show) {
  overflow: hidden;
}

body:has(.age-gate.show) nav {
  pointer-events: none;
}

details summary {
  cursor: pointer;
}

details[open] summary {
  margin-bottom: 10px;
}

details summary::-webkit-details-marker {
  display: none;
}

details summary::marker {
  content: none;
}

details summary::before,
details summary::after {
  content: '';
}

details.state-details summary::after {
  content: '+';
  padding-left: 1ch;
}

details.state-details[open] summary::after {
  content: '-';
}

#online-states div:last-child div:last-child {
  display: none;
}

.button-unset {
  all: unset;
  cursor: pointer;
}

:focus-visible,
.button-unset:focus-visible {
  outline: 2px solid var(--color--dark-green);
}

@keyframes fade {
  0% {
    display: none;
    opacity: 0;
  }

  0.01% {
    display: block;
    opacity: 0;
  }

  100% {
    display: block;
    opacity: 1;
  }
}

/* home */
.recipes-container.swiper-slide {
  width: 246px;
}

.modal-open {
  animation: fadeIn .4s ease;
  animation-fill-mode: forwards;
}

.modal-closed {
  animation: fadeIn .4s ease;
  animation-fill-mode: backwards;
}

.product-card-wrapper:hover .small-3.product-card {
  color: var(--color--white);
}

.product-card:hover .product-card-image {
  transform: translate(0, -20px);
}

@keyframes marquee {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(calc(-100% - 1rem));
  }
}

@media (prefers-reduced-motion: reduce) {
  .marquee-content {
    animation-play-state: paused;
  }
}

.marquee-content:hover {
  animation-play-state: paused;
}

@media screen and (min-width: 2500px) {
  .press-placeholder {
    display: none;
  }
}

.press-grid-wrapper {
  scrollbar-width: none;
}

.press-grid-wrapper::-webkit-scrollbar {
  display: none;
}

/* product page */
.pagination-wrapper:has(#previous-link.visually-hidden) a {
  margin-left: auto;
}

.pagination-wrapper:has(#next-link.visually-hidden) a {
  margin-right: auto;
}

.other-product:hover .other-product-image {
  transform: translate(0, -20px);
}